// /src/components/Pagination/index.js
import React from 'react';
import './Pagination.css';
const Pagination = ({ page, totalPages, setPage }) => {
    const changePage = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setPage(newPage);
        }
    };

    return (
        <div className="pagination-container">
            <button onClick={() => changePage(1)} disabled={page <= 1} className="page-button">First</button>
            <button onClick={() => changePage(page - 1)} disabled={page <= 1} className="page-button">Previous</button>
            <span className="page-info"> Page {page} </span>
            <button onClick={() => changePage(page + 1)} disabled={page == totalPages} className="page-button">Next</button>
            <button onClick={() => changePage(totalPages)} disabled={page == totalPages} className="page-button">Last</button>
        </div>
    );
};

export default Pagination;
