import axios from 'axios';

let API_BASE_URL = 'http://localhost:3000';

if (process.env.NODE_ENV == 'production') {
    API_BASE_URL = "https://api.skooltrackr.com"
}

export const fetchMembershipTrends = async (communityName) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/api/membership-trends/${communityName}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching communities:", error);
        throw error;
    }
};

export const fetchCommunities = async ({ page = 1, sort = 'cost', order = 'desc', searchTerm = false }) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/communities`, {
            params: { page, sortBy: sort, order, searchTerm: encodeURIComponent(searchTerm) }
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching communities:", error);
        throw error;
    }
};

export const subscribe = async (email) => {
    try {
        const respnse = await axios.post(`${API_BASE_URL}/subscribe`, {
            email: email
        })
        return respnse.status;
    } catch (err) {
        console.error("error while subscribing: ", err);
        throw err;
    }
}
