import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { fetchMembershipTrends } from '../services/api';
import { Helmet } from 'react-helmet';
import './MembershipTrends.css';

Chart.register(...registerables);

const generateTrendAnalysis = (trends) => {
    if (trends.length === 0) return "No membership data available.";

    const firstPoint = trends[0].totalMembers;
    const lastPoint = trends[trends.length - 1].totalMembers;
    const totalChange = lastPoint - firstPoint;
    const percentageChange = ((totalChange / firstPoint) * 100).toFixed(2);

    if (totalChange > 0) {
        return `The community has grown significantly over the observed period, with a membership increase of ${totalChange} members (${percentageChange}%).`;
    } else if (totalChange < 0) {
        return `The community has seen a decrease in membership over the observed period, with a reduction of ${totalChange * -1} members (${percentageChange}%).`;
    } else {
        return "The community's membership has remained stable over the observed period.";
    }
};

const MembershipTrends = () => {
    const { communityName } = useParams();
    const [communityData, setCommunityData] = useState({});
    const [trends, setTrends] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const getTrends = async () => {
            try {
                const { community, trends } = await fetchMembershipTrends(communityName);
                setCommunityData(community);
                setTrends(trends);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        getTrends();
    }, [communityName]);

    if (loading) {
        return (
            <div className="loading-spinner">
                <div>Loading...</div>
            </div>
        );
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    const dates = trends.map(trend => trend.date);
    const members = trends.map(trend => trend.totalMembers);

    const data = {
        labels: dates,
        datasets: [
            {
                label: 'Membership Over Time',
                data: members,
                fill: false,
                backgroundColor: 'rgb(75, 192, 192)',
                borderColor: 'rgba(75, 192, 192, 0.2)',
            },
        ],
    };

    const options = {
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                    tooltipFormat: 'P'
                }
            }
        },
        interaction: {
            mode: 'index',
            intersect: false,
        },
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return `${context.dataset.label}: ${context.raw}`;
                    }
                }
            }
        }
    };

    const trendAnalysis = generateTrendAnalysis(trends);

    return (
        <div className="membership-trends-container">
            <Helmet>
                <title>Membership Trends for {communityData.displayName} | Skool Trackr</title>
                <meta name="description" content={`View the membership trends over time for the ${communityData.displayName} community on Skool Trackr. Analyze daily changes and overall growth.`} />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": communityData.displayName,
                        "description": communityData.description,
                        "url": `https://skool.com/${communityData.name}`,
                        "member": {
                            "@type": "QuantitativeValue",
                            "value": communityData.totalMembers
                        }
                    })}
                </script>
            </Helmet>
            <h1>Membership Trends for {communityData.displayName}</h1>
            <div className="community-info">
                <p><strong>Description:</strong> {communityData.description}</p>
                <p><strong>Total Members:</strong> {communityData.totalMembers}</p>
                <p><strong>Status:</strong> {communityData.paid ? 'Paid' : 'Free'}</p>
                <p><strong>Monthly Recurring Revenue (MRR):</strong> {communityData.earnings.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</p>
                <Link to={`https://skool.com/${communityData.name}`} target="_blank" rel="noopener noreferrer" className="community-link">Visit Community Page</Link>
            </div>
            <div className="trend-analysis">
                <h2>Trend Analysis</h2>
                <p>{trendAnalysis}</p>
            </div>
            <div className="chart-container">
                <Line data={data} options={options} />
            </div>
            <div className="related-links">
                {/* <h2>Related Communities</h2> */}
                {/* Assuming you have a function to fetch and display related communities */}
                {/* <RelatedCommunities communityId={communityId} /> */}
            </div>
        </div>
    );
};

export default MembershipTrends;
