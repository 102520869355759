import React, { useState, useEffect } from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import { subscribe } from '../services/api';

const Footer = () => {
  const [subscribeStatus, setSubscribeStatus] = useState('');
  const [email, setEmail] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    let timer;
    if (showSuccess) {
      timer = setTimeout(() => setShowSuccess(false), 2000); // Hide success message after 3 seconds
    }
    return () => clearTimeout(timer);
  }, [showSuccess]);

  const handleSubscribe = async (event) => {
    event.preventDefault();
    try {
      await subscribe(email);
      setSubscribeStatus('Subscribed successfully!');
      setShowSuccess(true); // Show success message
      setEmail(''); // Clear the email field
    } catch (error) {
      setSubscribeStatus('Failed to subscribe. Please try again.');
    }
  };


  return (
    <footer className="site-footer">
      <div className="footer-content">
        <div className="footer-column">
          <p>Keeping a pulse on the most vibrant communities on Skool.com. Discover which communities are growing and thriving.</p>
          <p>We value your input and would love to hear what you think. Your suggestions help us to improve and serve you better.</p>
          <a href="mailto:feedback@skooltrackr.com" className="feedback-button">Send Feedback</a>
        </div>

        <div className="footer-column">
          <h3>Explore More:</h3>
          <ul>
            <li><Link to="/">Skool Community Trackr</Link></li>
            <li><Link to="/top-earning-communities-in-skool">Top Earning Communities</Link></li>
            <li><Link to="/top-growing-communities-in-skool-daily">Top Growing Communities Daily</Link></li>
            {/* Add more list items with links as needed */}
          </ul>
        </div>

        <div className="footer-column">
          <div className="subscription-container">
            <div className="subscription-prompt">
              Start getting interesting updates about Skool
            </div>
            <form onSubmit={handleSubscribe} style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <input
                className="email-input"
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <button type="submit" className="subscribe-button">Subscribe</button>
            </form>
            {showSuccess && (
              <div className="subscribe-success">
                {subscribeStatus}
              </div>
            )}
          </div>
        </div>

      </div>
    </footer>
  );
};

export default Footer;
