
import './App.css';
import CommunityList from './components/CommunityList';
import React, { useState } from 'react';
import Header from "./components/Header";
import Footer from './components/Footer';
import TopEarningCommunities from './components/TopEarningCommunities';
import MembershipTrends from './components/MembershipTrends';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import TopGrowingCommunitiesDaily from './components/TopGrowingCommunitiesDaily';


function App() {
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('cost');
  const [order, setOrder] = useState('desc');
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <Router>
      <div className="App">
        <Header />
        {/* 'Switch' is replaced by 'Routes' for defining routes */}
        <Routes>
          <Route exact path="/" element={<CommunityList
            page={page} setPage={setPage}
            sort={sort} setSort={setSort}
            order={order} setOrder={setOrder}
            searchTerm={searchTerm} setSearchTerm={setSearchTerm}
          />} />
          <Route path="/top-earning-communities-in-skool" element={<TopEarningCommunities />} />
          <Route path="/top-growing-communities-in-skool-daily" element={<TopGrowingCommunitiesDaily />} />
          <Route path="/membership-trends/:communityName" element={<MembershipTrends />} />

        </Routes>


        <Footer />
      </div>
    </Router>
  );
}

export default App;
