import React from "react";
import { ASCII } from "../theme/ASCIIStyles.js";
import './header.css';
import { Link } from 'react-router-dom';
import { NavLink, useLocation } from 'react-router-dom';

function Header() {

  const location = useLocation();

  return (
    <header className="header-container">

      <div className="logo">
        <NavLink to="/">
          <span className="logo-letter" style={{ color: '#1b2d48' }}>s</span>
          <span className="logo-letter" style={{ color: '#FFC300' }}>k</span>
          <span className="logo-letter" style={{ color: '#C70039' }}>o</span>
          <span className="logo-letter" style={{ color: '#83aff0' }}>o</span>
          <span className="logo-letter" style={{ color: '#581845' }}>l</span>
          <span className="logo-letter" style={{ color: '#FF5733' }}> t</span>
          <span className="logo-letter" style={{ color: '#F08080' }}>r</span>
          <span className="logo-letter" style={{ color: '#C70039' }}>a</span>
          <span className="logo-letter" style={{ color: '#900C3F' }}>c</span>
          <span className="logo-letter" style={{ color: '#581845' }}>k</span>
          <span className="logo-letter" style={{ color: '#3c649f' }}>r</span></NavLink>
      </div>


      {/* <nav className="header-nav">
        <NavLink to="/" className={location.pathname === '/' ? 'active' : ''}>Home</NavLink>
        <NavLink to="/top-earning-communities-in-skool" className={location.pathname === '/top-earning-communities-in-skool' ? 'active' : ''}>Top Earning</NavLink>
        <NavLink to="/top-growing-communities-in-skool-daily" className={location.pathname === '/top-growing-communities-in-skool-daily' ? 'active' : ''}>Top Growing</NavLink>
      </nav> */}
    </header>

  );
}

export default Header;
