import React, { useState, useEffect } from 'react';
import useCommunities from '../../hooks/useCommunities';
import { Helmet } from 'react-helmet';
import './TopGrowingCommunitiesDaily.css';
import { subscribe } from '../../services/api';

function TopGrowingCommunitiesDaily() {
  const { loading, error, communities } = useCommunities({
    page: 1,
    sort: 'changes.dailyChanges.membersDifference',
    order: 'desc',
    searchTerm: ''
  });
  const [subscribeStatus, setSubscribeStatus] = useState('');
  const [email, setEmail] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    let timer;
    if (showSuccess) {
      timer = setTimeout(() => setShowSuccess(false), 2000); // Hide success message after 3 seconds
    }
    return () => clearTimeout(timer);
  }, [showSuccess]);

  const handleSubscribe = async (event) => {
    event.preventDefault();
    try {
      await subscribe(email);
      setSubscribeStatus('Subscribed successfully!');
      setShowSuccess(true); // Show success message
      setEmail(''); // Clear the email field
    } catch (error) {
      setSubscribeStatus('Failed to subscribe. Please try again.');
    }
  };

  if (loading) return <p>Loading top earning communities...</p>;
  if (error) return <p>Error fetching top earning communities.</p>;

  return (
    <main>
      <Helmet>
        <title>Top Earning Communities - SkoolTrackr</title>
        <meta name="description" content="Discover the highest-earning communities on SkoolTrackr. See which groups excel and join the ranks of the best-performing peers." />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "ItemList",
            "itemListElement": communities.map((community, index) => ({
              "@type": "ListItem",
              "position": index + 1,
              "url": `https://skool.com/${community.name}`,
              "name": community.displayName,
              "image": community.coverSmallUrl,
              "description": community.description
            }))
          })}
        </script>
      </Helmet>

      <header>
        <h1>Top Growing Communities</h1>
        <p>Join the ranks of the best-performing communities, learn from the top earners, and connect with like-minded peers.</p>

        <div className="subscription-container" style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <form onSubmit={handleSubscribe} style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <input
              className="email-input"
              type="email"
              placeholder="Enter your email to get interesting updates about Skool"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={{ padding: '10px', flexGrow: 1, borderRadius: '5px', border: '1px solid #ccc' }}
            />
            <button type="submit" style={{ padding: '10px 20px', borderRadius: '5px', border: 'none', backgroundColor: '#007bff', color: 'white', cursor: 'pointer' }}>
              Subscribe
            </button>
          </form>
          {showSuccess && (
            <div className="subscribe-success">
              {subscribeStatus}
            </div>
          )}

        </div>
      </header>

      <section className="community-list">
        {communities.map((community, index) => (
          <article key={community.id} className={`community-item ${index < 3 ? 'top-earner' : ''}`}>
            {index < 3 && <div className="top-earner-badge">Top #{index + 1}</div>}
            <img src={community.coverSmallUrl} alt={`${community.displayName} Cover`} className="community-image" />
            <div className="community-info">
              <h2>{community.displayName}</h2>
              <p className="membersAdded">Members added daily: {community.changes.dailyChanges.membersDifference}</p>
              <p className="earnings">Earnings: ${community.earnings.toLocaleString()}</p>
              <p className="members">Members: {community.totalMembers.toLocaleString()}</p>
              <p className="description">{community.description}</p>
              <a href={`https://skool.com/${community.name}`} target="_blank" rel="noopener noreferrer" className="learn-more-link">Learn more</a>
            </div>
          </article>
        ))}
      </section>
    </main>
  );
}

export default TopGrowingCommunitiesDaily;
