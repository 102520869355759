import React from 'react';
import './CommunityTable.css'; // Make sure to create this CSS file
import { Link } from 'react-router-dom';

const CommunityTable = ({ communities, setSort, setOrder, sort, order }) => {

    const renderMemberChange = (difference) => {

        if (difference > 0) {
            return <span style={{ color: 'green' }}>↑ {difference}</span>;
        } else if (difference < 0) {
            return <span style={{ color: 'red' }}>↓ {difference}</span>;
        } else {
            return 0
        }
    };

    const handleHeaderClick = (newSort) => {
        if (sort === newSort) {
            setOrder(order === 'asc' ? 'desc' : 'asc');
        } else {
            setSort(newSort);
            setOrder('desc');
        }
    };

    const renderSortIcon = (column) => {
        if (sort === column) {
            return order === 'asc' ? '↑' : '↓';
        }
        return '';
    };

    return (
        <div className="community-table-container"> {/* Container to handle horizontal scrolling */}

            <table className="community-table">
                <thead>
                    <tr>
                        <th>Metrics → </th>
                        <th colSpan="4" className="center-align">Members</th>

                        <th onClick={() => handleHeaderClick('cost')} className={sort === 'cost' ? 'sortable sorted-column' : 'sortable'}>
                            Cost {renderSortIcon('cost')}
                        </th>
                        {/* <th onClick={() => handleHeaderClick('changes.dailyChanges.costDifference')} className={sort === 'changes.dailyChanges.costDifference' ? 'sorted-column' : ''}>
                        Daily Changes {renderSortIcon('changes.dailyChanges.costDifference')}
                    </th> */}
                        <th onClick={() => handleHeaderClick('paid')} className={sort === 'paid' ? 'sortable sorted-column' : 'sortable'}>
                            Status {renderSortIcon('paid')}
                        </th>
                        <th onClick={() => handleHeaderClick('earnings')} className={sort === 'earnings' ? 'sortable sorted-column' : 'sortable'}>
                            MRR {renderSortIcon('earnings')}
                        </th>
                    </tr>
                    <tr>

                        <th>Community Names ↓</th>

                        <th onClick={() => handleHeaderClick('totalMembers')} className={sort === 'totalMembers' ? 'sortable sorted-column' : 'sortable'}>
                            Current {renderSortIcon('totalMembers')}
                        </th>

                        <th onClick={() => handleHeaderClick('changes.dailyChanges.membersDifference')} className={sort === 'changes.dailyChanges.membersDifference' ? 'sortable sorted-column' : 'sortable'}>
                            Daily Changes {renderSortIcon('changes.dailyChanges.membersDifference')}
                        </th>
                        <th onClick={() => handleHeaderClick('changes.weeklyChanges.membersDifference')} className={sort === 'changes.weeklyChanges.membersDifference' ? 'sortable sorted-column' : 'sortable'}>
                            Weekly Changes {renderSortIcon('changes.weeklyChanges.membersDifference')}
                        </th>
                        <th>Trends</th>
                        {/* 
                        <th></th>

                        <th></th>

                        <th></th> */}

                    </tr>
                </thead>
                <tbody>
                    {communities.map((community) => (
                        <tr key={community._id}>
                            <td>
                                <a href={`https://skool.com/${community.name}`} target="_blank" rel="noopener noreferrer">
                                    {community.displayName}
                                </a>
                            </td>
                            <td>{community.totalMembers}</td>
                            <td>{renderMemberChange(community?.changes?.dailyChanges?.membersDifference)}</td>
                            <td>{renderMemberChange(community?.changes?.weeklyChanges?.membersDifference)}</td>
                            <td>
                                <Link to={`/membership-trends/${community.name}`}>View Trends</Link>
                            </td>
                            <td>{community.cost.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                            {/* <td>{renderMemberChange(community?.changes?.dailyChanges?.costDifference)}</td> */}
                            <td>{community.paid ? 'Paid' : 'Free'}</td>
                            <td>{community.earnings.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default React.memo(CommunityTable);
