import React, { useState, useEffect, useRef } from 'react';
import useCommunities from '../../hooks/useCommunities';
import Pagination from '../Pagination';
import CommunityTable from '../CommunityTable';
import { subscribe } from '../../services/api';
import './index.css'

const CommunityList = ({ page, setPage, sort, setSort, order, setOrder, searchTerm, setSearchTerm }) => {
    const { loading, error, communities, totalPages } = useCommunities({ page, sort, order, searchTerm });
    const [email, setEmail] = useState('');
    const [subscribeStatus, setSubscribeStatus] = useState('');
    const [inputValue, setInputValue] = useState(searchTerm); // Local state for the input to ensure responsiveness
    const [showSuccess, setShowSuccess] = useState(false);


    // UseEffect for debouncing setSearchTerm based on inputValue
    useEffect(() => {
        const timerId = setTimeout(() => {
            setSearchTerm(inputValue);
        }, 777); // 500ms delay

        return () => clearTimeout(timerId); // Cleanup on effect unmount or inputValue change
    }, [inputValue, setSearchTerm]);


    useEffect(() => {
        let timer;
        if (showSuccess) {
            timer = setTimeout(() => setShowSuccess(false), 2000); // Hide success message after 3 seconds
        }
        return () => clearTimeout(timer);
    }, [showSuccess]);


    const handleSearch = (event) => {
        event.preventDefault()
        setInputValue(event.target.value); // Update the local state immediately for a responsive UI
    };

    const handleSubscribe = async (event) => {
        event.preventDefault();
        try {
            await subscribe(email);
            setSubscribeStatus('Subscribed successfully!');
            setShowSuccess(true); // Show success message
            setEmail(''); // Clear the email field
        } catch (error) {
            setSubscribeStatus('Failed to subscribe. Please try again.');
        }
    };


    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error fetching communities</div>;


    return (
        <div className="community-list-container" style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            {/* Top bar for search and subscription */}
            <div className="top-bar" style={{ paddingTop: 15, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', gap: '1rem' }}>
                <div className="subscription-container" style={{ flexGrow: 2, display: 'flex', flexDirection: 'column' }}>
                    <form onSubmit={handleSubscribe} style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <input
                            className="email-input"
                            type="email"
                            placeholder="Enter your email to get interesting updates about Skool"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            style={{ padding: '10px', flexGrow: 1, borderRadius: '5px', border: '1px solid #ccc' }}
                        />
                        <button type="submit" style={{ padding: '10px 20px', borderRadius: '5px', border: 'none', backgroundColor: '#007bff', color: 'white', cursor: 'pointer' }}>
                            Subscribe
                        </button>
                    </form>
                    {showSuccess && (
                        <div className="subscribe-success">
                            {subscribeStatus}
                        </div>
                    )}

                </div>
                <input
                    className="search-input"
                    type="text"
                    placeholder="Search communities..."
                    value={inputValue}
                    onChange={handleSearch}
                    autoFocus
                    style={{ padding: '10px', flexGrow: 1, borderRadius: '5px', border: '1px solid #ccc' }}
                />
            </div>

            <CommunityTable
                communities={communities}
                setSort={setSort}
                setOrder={setOrder}
                sort={sort}
                order={order}
            />

            <Pagination page={page} totalPages={totalPages} setPage={setPage} />
        </div>
    );
};

export default React.memo(CommunityList);