// /src/hooks/useCommunities.js
import { useState, useEffect } from 'react';
import { fetchCommunities } from '../services/api';

const useCommunities = ({ page, sort, order, searchTerm }) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [communities, setCommunities] = useState([]);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const data = await fetchCommunities({ page, sort, order, searchTerm });
                setCommunities(data.communities ? data.communities : data);
                setTotalPages(data.totalPages); // Assume API returns total pages
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [page, sort, order, searchTerm]);

    return { loading, error, communities, totalPages, searchTerm };
};

export default useCommunities;
